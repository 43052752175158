export const SINGLE_CALL_BALANCES_ADDRESS = '0xb1f8e55c7f64d203c1400b9d8555d050f94adf39'
export const SINGLE_CALL_BALANCES_ADDRESS_GOERLI = '0x9788C4E93f9002a7ad8e72633b11E8d1ecd51f9b'
// TODO(SEPOLIA) There is currently no balance call address for Sepolia
export const SINGLE_CALL_BALANCES_ADDRESS_SEPOLIA = ''
export const SINGLE_CALL_BALANCES_ADDRESS_BSC = '0x2352c63A83f9Fd126af8676146721Fa00924d7e4'
export const SINGLE_CALL_BALANCES_ADDRESS_OPTIMISM = '0xB1c568e9C3E6bdaf755A60c7418C269eb11524FC'
export const SINGLE_CALL_BALANCES_ADDRESS_POLYGON = '0x2352c63A83f9Fd126af8676146721Fa00924d7e4'
export const SINGLE_CALL_BALANCES_ADDRESS_AVALANCHE = '0xD023D153a0DFa485130ECFdE2FAA7e612EF94818'
// export const SINGLE_CALL_BALANCES_ADDRESS_FANTOM = '0x07f697424ABe762bB808c109860c04eA488ff92B'
export const SINGLE_CALL_BALANCES_ADDRESS_ARBITRUM = '0x151E24A486D7258dd7C33Fb67E4bB01919B7B32c'
